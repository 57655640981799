import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';


const EventImageCarousel = () => {


  const divRef = useRef(null);

  useEffect(() => {
    const target = divRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the 'visible' class when the element is in the viewport
            entry.target.classList.add('visible');
          } else {
            // Optionally, remove the class when it is out of the viewport
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.5, // The element must be at least 50% visible to trigger the effect
      }
    );

    // Start observing the target element
    if (target) {
      observer.observe(target);
    }

    // Cleanup observer on component unmount
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);



  return (
    <div>
      <div className="carousel">
        <img src="/Images/event-img.jpg" alt="Event" className="event-image" />
      </div>
      <div className="image-style">
        <img  className="image-style-img" src="./Images/nisadya.jpeg" alt="Nisadya"  />
      </div>

      {/* Scrolling Text Container with zoom-in effect */}
      <div
        className="zoom-in"
        ref={divRef}
      >
        <div
          style={{
            marginTop:"40px",
            display: "inline-block",
            textAlign: "left",
            fontSize: "1.3rem",
            fontFamily:'monospace',
            marginLeft:'30px',
            fontFamily: "Open Sans"
          }}
        >
          <span ><b>Nisadya 5th Edition </b></span>
          <br></br>
          <span>Welcome to Nisadya 2025:</span>
          <br></br>
          <span>Organized by the Department of Management Studies, NIT Trichy, Nisadya is a </span>
          <br></br>
          <span>one-of-a-kind event that merges business brilliance with cultural vibrance.</span>
          <br></br>
          <i class="fas fa-briefcase" style={{fontSize:'1rem',color:'brown'}}></i>

   
          <span> Theme for this year:</span> 
          <br></br>
          <span style={{color:'blue'}}>"AI in Action: Leading with Responsible Business Innovations".</span>
          <br></br>
          <span> Explore how cutting-edge AI technologies are shaping the future of business</span>
          <br></br>
          <span>in Responsible and Impactful ways.</span>
        </div>
      </div>
    </div>
  );
};

export default EventImageCarousel;
