import React, { useEffect, useState , useRef} from "react";
import { useNavigate } from "react-router-dom";
import "./Eventlist.css"; 

function EventCard({event}) {
  const navigate = useNavigate();
  const divRef = useRef(null);

  useEffect(() => {
    console.log(event)
    const target = divRef.current;
    
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Add the 'visible' class when the element is in the viewport
            entry.target.classList.add('visible');
          } else {
            // Optionally, remove the class when it is out of the viewport
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.5, // The element must be at least 50% visible to trigger the effect
      }
    );

    // Start observing the target element
    if (target) {
      observer.observe(target);
    }

    // Cleanup observer on component unmount
    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

 const handleCardClick = (eventId) => {
  navigate(`/event/${eventId}`); 
};

return (

            <div ref={divRef} className="zoom-in">
            {event.map((eve,ind) => (
                        <div
                        key={eve.id}
                        className="event-card"
                        onClick={() => handleCardClick(eve.id)}
                      >  
                        <div className="event-icon-rounder">  
                        <div className="event-icon-wrapper-left">
          
                          </div>          
          
                          <div className="event-icon-wrapper">
                            <i className={`${eve.icon} event-icon`} style={{ color: "black" }}></i>
                          </div>
                          <div className="event-icon-wrapper-right">
          
                          </div>
          
                        </div>
                        <h4 style={{ color: "black", fontSize: "20px", fontWeight: "bold" }}>
                          {eve.event}
                        </h4>
                        <p style={{ color: "black", fontSize: "15px",textAlign:"left" }}>{eve.description}</p>
                        <p style={{ color: "blue", fontSize: "15px", cursor: "pointer" }}>
                          Event details
                        </p>
                      </div>
          ))} 


            </div>

);
}

export default EventCard;