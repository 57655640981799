import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Eventlist.css"; // Custom styles for event list
import Home from "./Home";
import Schedule from "./Schedule";
import EventCard from "./EventCard";

function EventList() {
	const [events, setEvents] = useState([]); // State to store the event details
	const [error, setError] = useState(null); // State for handling errors
	const [loading, setLoading] = useState(true); // State for loading indication
	const [showVideo, setShowVideo] = useState(false);

	const eventyoutube = {
		youtube: "https://www.youtube.com/embed/VIDEO_ID",
		youtube_title: "Your Video Title",
	};

	const handleVideoClick = () => {
		setShowVideo(!showVideo);
	};

	const navigate = useNavigate();

	// Fetch event details from the backend API
	useEffect(() => {
		// Fetch event details from the backend API

		/*axios
        .get(`http://localhost:3001/fetch-eventlists`) // Fetch event data
        .then((response) => {
          console.log("Event data fetched:", response.data); // Log the response to verify data
          setEvents(response.data.events); // Save event data to state
          setLoading(false); // Stop loading once the data is fetched
        })
        .catch((error) => {
          console.error("Error fetching the event data:", error);
          setError("Error fetching event details"); // Handle error case
          setLoading(false); // Stop loading on error
        });*/
		let eventResponse = [
			[
				{
					id: "1",
					event: "Sankalp",
					description:
						"Ready to transform your ideas into actionable success? Sankalp is the ultimate platform where future business innovators bring their strategies .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-lightbulb fa-3x",
				},
				{
					id: "2",
					event: "Chanakya",
					description:
						"Ready to take your leadership skills to the next level? CHANAKYA is where future business leaders rise to the challenge! .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-bullseye fa-3x",
				},
			],
			[
				{
					id: "3",
					event: "Merx",
					description:
						"Ready to revolutionize the way brands connect with audiences? MERX is the ultimate marketing showdown where creative minds .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-chart-bar fa-3x",
				},
				{
					id: "4",
					event: "Praxis",
					description:
						"Are you ready to master the art of operational excellence? Praxis is the ultimate operations challenge where future business  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-cogs fa-3x",
				},
			],
			[
				{
					id: "5",
					event: "Vriddhi",
					description:
						"Are you ready to enhance your financial acumen? Vriddhi is the premier competition for aspiring finance professionals to showcase  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-seedling fa-3x",
				},
				{
					id: "6",
					event: "Pravaran",
					description:
						"Are you ready to shape the future of talent management? Pravaran is the ultimate HR event where aspiring leaders demonstrate their  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-pencil-ruler fa-3x",
				},
			],
			[
				{
					id: "7",
					event: "Strategy Sprint",
					description:
						"Are you ready to lead businesses to new heights? Strategy Sprint is the high-energy consulting competition where the sharpest   .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-wallet fa-3x",
				},
				{
					id: "8",
					event: "Serpentine Draft",
					description:
						"Are you ready to dive into the thrilling world of cricket strategy? Serpentine Draft is the ultimate IPL-themed event where   .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-chess fa-3x",
				},
			],
			[
				{
					id: "9",
					event: "Uttar",
					description:
						"Are you ready to challenge your knowledge and showcase your skills? Uttar B Quiz is the ultimate quiz competition where curious minds  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-comments fa-3x",
				},
				{
					id: "10",
					event: "Gavel",
					description:
						"Are you ready to sharpen your debating skills and engage in thought-provoking discussions? Gavel is the premier event where passionate   .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-balance-scale fa-3x",
				},
			],
			[
				{
					id: "11",
					event: "Manifest",
					description:
						"Get ready for a fun and interactive twist on networking and business strategy! Manifest Business Charades is the ultimate event where  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-bolt fa-3x",
				},
				{
					id: "12",
					event: "Sports Quiz",
					description:
						"Are you a sports enthusiast with an unquenchable thirst for knowledge? The SPORTS QUIZ is the ultimate challenge for those who live and breathe sports! .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-trophy fa-3x",
				},
			],
			[
				{
					id: "13",
					event: "Brand Quiz",
					description:
						"Ready to test your knowledge of the branding world? The BRAND QUIZ is your opportunity to showcase your expertise in marketing, .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-tags fa-3x",
				},
				{
					id: "14",
					event: "AI ML Quiz",
					description:
						"Are you passionate about artificial intelligence and machine learning? The AI ML QUIZ is the ultimate  .........",
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-brain fa-3x",
				},
			],
		];
		setEvents(eventResponse);
		setLoading(false);
	}, []);

	return (
		<>
			<Home />
			<div>
				{loading && <p>Loading event details...</p>}{" "}
				{/* Show loading message */}
				{error && <p className="error">{error}</p>} {/* Show error message */}
				{events.length > 0 && !loading ? (
					<div className="event-list-container">
						{events.map((event, ind) => (
							<EventCard key={ind} event={event} />
						))}
					</div>
				) : (
					!loading && !error && <p>No event found</p>
				)}
			</div>

			<div className="video-container">
				{/* {!showVideo && ( // Show the button only if the video is not displayed
        <button className="video-button" onClick={handleVideoClick}>
          Watch Video
        </button>
      )} */}
				{showVideo && (
					<iframe
						src={eventyoutube.youtube}
						title={eventyoutube.youtube_title}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						className="video-iframe"
					></iframe>
				)}

				<Schedule />
			</div>
		</>
	);
}

export default EventList;
