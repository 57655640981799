import React from 'react';

const Footer = () => {
  return (
    <footer style={footerStyle}>
      <p>
        2024 © All rights Reserved by{' '}
        <a
          href="https://cubeaisolutions.vercel.app/" 
          target="_blank"
          rel="noopener noreferrer"
          style={linkStyle}
        >
          CubeAISolutions
        </a>
      </p>
    </footer>
  );
};

// Styling
const footerStyle = {
  textAlign: 'center',
  padding: '0.2em 0',
  backgroundColor: '#f8f9fa',
  fontSize: '14px',
  color: '#333',
  marginTop: '20px', 

};

const linkStyle = {
  color: '#007bff',
  textDecoration: 'none',
};

export default Footer;
