import React, { useEffect, useRef, useCallback, useState } from 'react';
import './Home.css';
import { Link } from 'react-scroll';
import EventImageCarousel from './EventImageCarousel';
import { DiVisualstudio } from 'react-icons/di';

const Home = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Set loading duration to 2 seconds

    return () => clearTimeout(timer); // Cleanup timer
  }, []);

  const toggleMenu = useCallback(() => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      sidebarRef.current.classList.add('show');
    } else {
      sidebarRef.current.classList.remove('show');
    }
  }, [menuOpen]);

  // Consolidate the scroll functionality into one useEffect hook
  useEffect(() => {
    const header = document.getElementById('navigation');
    
    // Check if header exists before adding scroll event listener
    if (header) {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          header.classList.add('scrolled');
        } else {
          header.classList.remove('scrolled');
        }
      };

      // Attach scroll event listener
      window.addEventListener('scroll', handleScroll);

      // Cleanup event listener when the component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      console.error("Header element not found with id 'navigation'");
    }
  }, []); // Run once on component mount

  function isElementInViewport(el) {
    const rect = el.getBoundingClientRect();
    return rect.top >= 0 && rect.bottom <= window.innerHeight;
  }

  function handleScrollForAnimations() {
    // Loop through elements with scroll-animation class
    document.querySelectorAll('.scroll').forEach(function(element) {
      if (isElementInViewport(element)) {
        element.classList.add('visible');
      }
    });
  }

  // Add event listener for scroll event (for animations)
  useEffect(() => {
    window.addEventListener('scroll', handleScrollForAnimations);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('scroll', handleScrollForAnimations);
  }, []);

  if (isLoading) {
    // Render loading screen
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return (
    <>
    <header id="navigation" className="navbar-inverse navbar-fixed-top animated-header">
    <div className="title-bar">
      <div className="containerlogo">
        <div className="navbar-brand">
          <a href="/">
            <img src="/Images/nitlogo.png" alt="Logo" style={{ width: '200px' }} />
          </a>
        </div>
      </div>

      <div className="navbar-header">
        <div className="navlinks">
          <nav className="nav-links">
            <Link style={{ padding: '10px', color: 'white' }} to="full-width-image" smooth={true} duration={500} className="event-link">
              Home 
            </Link>
            <Link style={{ padding: '10px' }} to="event-card" smooth={true} duration={500} className="event-link">
              Events
            </Link>
            <Link style={{ padding: '10px' }} to="schedule-container" smooth={true} duration={500} className="event-link">
              Schedule
            </Link>
            <Link style={{ padding: '10px' }} to="contact-us-container" smooth={true} duration={500} className="event-link">
              Contact Us
            </Link>
          </nav>
        </div>
      </div>

      {/* Hamburger Icon for Mobile View */}
      <div className="hamburger" onClick={toggleMenu}>
        {menuOpen ? (
          <span className="close-icon">&#10005;</span>
        ) : (
          <>
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </>
        )}
      </div>
          {/* Sidebar navigation for Mobile View */}
    <div ref={sidebarRef} className={`sidebar ${menuOpen ? 'show' : ''}`}>
      <ul className="sidebar-nav">
       <div className='home-text'><li><Link to="full-width-image" smooth={true} duration={500} onClick={toggleMenu} className="event-link">Home</Link></li></div> 
        <li><Link to="event-card" smooth={true} duration={500} onClick={toggleMenu} className="event-link">Events</Link></li>
        <li><Link to="schedule-container" smooth={true} duration={500} onClick={toggleMenu} className="event-link">Schedule</Link></li>
        <li><Link to="contact-us-container" smooth={true} duration={500} onClick={toggleMenu} className="contact-info">Contact Us</Link></li>
      </ul>
    </div>
    </div>


  </header>
  


      {/* Event Image Carousel */}
      <EventImageCarousel />

      <div className="centered-container">
        <div>
          <div className="scroll">
            <section className="prizes">
              <h1 className="event-heading">
                <span>PRIZES</span>              
                </h1>
                <div className='line'></div>
              <p style={{fontSize:'20px',color:'black',marginTop:'10px'}}>Cash  Prizes <b>worth  2L</b></p>
            </section>

            <section className="events">
              <h1 className="event-heading">
                <span>EVENTS</span>
              </h1>
              <div className='line'></div>
              <p style={{fontSize:'20px',color:'gray',marginTop:'10px'}}>15  exciting  events!</p>
            </section>
          </div>
        </div>
      </div>

    </>
  );
};

export default Home;
